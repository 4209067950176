import { useParams, useRouteContext } from "@tanstack/react-router";
import { ArrowRightIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import * as z from "zod";
import { postMagicLinkSignUp } from "@/api";
import Agreements from "@/routes/-components/agreements";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@prime/ui/src/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@prime/ui/src/form";
import { Input } from "@prime/ui/src/input";

export default function VerifyEmail({
  business_name,
}: {
  business_name?: string;
}) {
  const { unauthenticatedApiClient, toast } = useRouteContext({
    from: "/invitations/$invitationId/claim/",
  });
  const invitationId = useParams({
    from: "/invitations/$invitationId/claim/",
    select: (params) => params.invitationId,
  });

  const formSchema = z.object({
    account_email: z
      .string()
      .email({ message: "Please enter a valid email address" }),
  });

  const redirectUri = `${window.location.origin}/authorize`;

  const [processing, setProcessing] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      account_email: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const email = values.account_email;
    setProcessing(true);
    postMagicLinkSignUp({
      apiClient: unauthenticatedApiClient,
      payload: {
        email,
        redirect_uri: redirectUri,
        state: { invitationId },
      },
    })
      .then(() => {
        setEmailSent(email);
      })
      .catch(() => {
        toast({
          title: "Sign up failed",
          description: "We were unable to sign you up. Please try again.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  if (emailSent) {
    return (
      <div className="flex w-full max-w-[480px] flex-col gap-6 p-6">
        <div className="gap-1">
          <p className="text-txt-primary text-2xl font-normal">
            Check Your Email
          </p>
          <p className="text-txt-tertiary text-sm">
            We sent an email to <span className="font-medium">{emailSent}</span>{" "}
            containing a verification link.
          </p>
        </div>
        <div>
          <Button
            onClick={() => setEmailSent(null)}
            variant={"outline"}
            className="h-12 rounded-md border-[2px] px-3 py-2 text-sm font-medium"
          >
            Start Over
          </Button>
        </div>
      </div>
    );
  }

  const buttonDisabled = processing;

  return (
    <div className="w-full max-w-[480px] py-6">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col items-center justify-start"
        >
          <div className="box-border flex w-full flex-1 flex-col items-start justify-start self-stretch overflow-hidden p-6">
            {business_name ? (
              <p className="text-btn text-sm font-medium uppercase">
                {business_name}
              </p>
            ) : null}
            <h1 className="text-txt-primary text-2xl font-medium">
              {"Let's Get Started"}
            </h1>
            <p className="text-txt-tertiary mb-6 text-sm">
              Please enter the business email address we will use to secure your
              account and send important messages.
            </p>

            <FormField
              control={form.control}
              name="account_email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl className="w-full">
                    <Input
                      type="email"
                      placeholder="Business Email Address"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="box-border flex flex-1 flex-col items-start justify-center gap-6 self-stretch overflow-hidden border-solid p-6 pt-0 text-left text-sm">
            <div className="bg-background-option w-full rounded-xl p-2 pb-4">
              <Agreements />
            </div>
            <Button className="w-full" type="submit" disabled={buttonDisabled}>
              <span>Continue</span>
              <ArrowRightIcon className="ml-2 h-4 w-4" />
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
