import { useAuth0 } from "@auth0/auth0-react";
import { ChevronDown, MenuIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";

import {
  disconnectCodatConnection,
  getApplications,
  getCodatConnection,
} from "@/api";
import { useApi } from "@/contexts/api";
import PopLogoSvg from "@/routes/-components/pop-logo.svg";
import { Application } from "@/types";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@prime/ui/src/alert-dialog";
import { Button } from "@prime/ui/src/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@prime/ui/src/dropdown-menu";

export function GlobalNavigation() {
  const auth = useAuth0();
  const apiClient = useApi();
  const [application, setApplication] = useState<Partial<Application> | null>(
    null
  );

  const isXero = useMemo(
    () => (application ? application?.partner_short_id === "xero" : false),
    [application]
  );
  const [xeroConnection, setXeroConnection] = useState<Record<
    string,
    unknown
  > | null>({ status: "Linked" });

  useEffect(() => {
    if (!application) {
      setApplication({}); // Prevents multiple requests
      getApplications({ apiClient }).then(({ applications }) => {
        setApplication(applications?.[0] || null);
      });
    }
  }, [apiClient, application]);

  useEffect(() => {
    if (isXero && !xeroConnection) {
      getCodatConnection({ apiClient })
        .then((connection) => {
          setXeroConnection(connection || null);
          console.log(connection);
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            setXeroConnection({ status: "Unlinked" });
          }
        });
    }
  }, [apiClient, isXero, xeroConnection]);

  const disconnectXero = () => {
    disconnectCodatConnection({ apiClient }).then(() => {
      setXeroConnection(null);
    });
  };

  return (
    <div className="flex w-full items-center justify-between border-b border-slate-200 p-6 lg:px-16">
      <div className="grid-col border-b-txt-inverted-dimmed grid gap-1.5 border-b pb-6">
        <PopLogoSvg className="text-illo-saturated w-32" />
        <h2 className="text-surface-banner font-normal">
          Small Business Loans
        </h2>
      </div>
      <div className="hidden flex-row items-center justify-start sm:flex">
        <div className="flex flex-row items-center justify-center overflow-hidden px-4 py-1.5">
          <a
            href="mailto:support@primeft.com"
            className="text-action-primary leading-[150%]"
          >
            Support
          </a>
        </div>
        <AlertDialog>
          <DropdownMenu>
            <DropdownMenuTrigger className="text-action-primary border-border-dark box-border flex h-[60px] max-w-[480px] flex-row items-center justify-center gap-2 overflow-hidden rounded-md border-[1px] border-solid px-4 py-3">
              {auth.user?.email}
              <ChevronDown className="text-action-primary h-5 w-5 shrink-0 overflow-hidden" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="min-w-[200px]">
              {isXero ? (
                <DropdownMenuItem className="flex w-full flex-col gap-2 border-b-[1px] border-gray-200">
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-txt-primary text-base">Xero</p>

                    <AlertDialogTrigger
                      disabled={xeroConnection?.status !== "Linked"}
                    >
                      <Button
                        disabled={xeroConnection?.status !== "Linked"}
                        className="hover:bg-action-negative relative ml-2 h-[unset] rounded-sm p-1 text-sm font-light after:absolute after:inset-0 after:flex after:items-center after:justify-center after:text-white after:opacity-0 after:content-['Disconnect'] hover:text-transparent hover:after:opacity-100"
                      >
                        {xeroConnection?.status === "Linked"
                          ? "Connected"
                          : "Disconnected"}
                      </Button>
                    </AlertDialogTrigger>
                  </div>
                  <p className="text-txt-tertiary text-sm">
                    {application?.applicant_business_name}
                  </p>
                </DropdownMenuItem>
              ) : null}
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() => auth.logout()}
              >
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Are you sure you want to disconnect from Xero?
              </AlertDialogTitle>
              <AlertDialogDescription>
                <br />
                <p>
                  {
                    "If you disconnect we won't be able to write back to your books. You will still be able to sign in to Prime using your Xero account."
                  }
                </p>
                <br />
                <p>
                  This action will diconnect your account from{" "}
                  <span className="font-medium">
                    {application?.applicant_business_name}
                  </span>{" "}
                  in Xero.
                </p>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className="mt-6 flex w-full flex-row items-center justify-between sm:justify-between">
              <AlertDialogCancel>No, take me back</AlertDialogCancel>
              <AlertDialogAction onClick={disconnectXero}>
                Yes, I am sure
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>

      <Button variant="icon" size="icon-sm" className="sm:hidden">
        <MenuIcon />
      </Button>
    </div>
  );
}
