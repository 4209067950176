import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";

import LoaderScreen from "@/routes/-components/loader-screen";

export const Route = createFileRoute("/xero/auth/")({
  pendingComponent: LoaderScreen,
  component: Page,
});

function Page() {
  useEffect(() => {
    const redirectUri = `${window.location.origin}/authorize`;
    const applyApiUrl = import.meta.env.VITE_APPLY_API_URL;
    window.location.href = `${applyApiUrl}/auth/connection?connection=xero&redirect_uri=${redirectUri}`;
  }, []);

  return <LoaderScreen />;
}
