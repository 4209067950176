import { Redirect, ToOptions } from "@tanstack/react-router";

import { Application } from "@/types";

export function getRouteByApplicationStage(application: Application): {
  to: Exclude<NonNullable<ToOptions["to"]>, "" | "./" | "../">;
  params: Exclude<NonNullable<Redirect["params"]>, boolean>;
  _pathname?: string;
} {
  if (!application) {
    return {
      to: "/",
      params: {
        error: "login_failed",
      },
    };
  }

  const isXero = application?.partner_short_id === "xero";
  /*
   * Pre-qualification Offer terms Related (If applicant is coming through prequal offer link)
   */
  if (
    application.stage === "offer_generated" ||
    application.stage === "offer_claimed"
  ) {
    return {
      to: "/applications/$applicationId/identity",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/identity`,
    };
  }

  /*
   * Ownership Related (Following Identification)
   */
  if (application.stage === "identification_completed") {
    return {
      to: "/applications/$applicationId/ownership",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/ownership`,
    };
  }

  /*
   * KYC and KYB related (Fraud Verification)
   */
  if (application.stage === "verification_review") {
    return {
      to: "/applications/$applicationId/verification/review",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/verification/review`,
    };
  }

  if (application.stage === "verification_approved") {
    return {
      to: "/applications/$applicationId/connect-bank",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/connect-bank`,
    };
  }

  if (application.stage === "verification_denied") {
    return {
      to: "/applications/$applicationId/verification/denied",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/verification/denied`,
    };
  }

  /*
   *Connect Bank Related (This is multistep process for user to connect bank)
   */
  if (application.stage === "connect_bank_started") {
    return {
      to: "/applications/$applicationId/connect-bank",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/connect-bank`,
    };
  }

  if (application.stage === "connect_bank_completed") {
    return {
      to: "/applications/$applicationId/connect-bank",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/connect-bank`,
    };
  }

  /*
   * Payment Provider Setup Related. Relies on MoneyService/Dwolla. After Connect bank these steps are performed
   */
  if (application.stage === "payment_provider_borrower_setup_completed") {
    return {
      to: "/applications/$applicationId/connect-bank",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/connect-bank`,
    };
  }

  if (
    application.stage ===
    "payment_provider_borrower_bank_account_setup_completed"
  ) {
    return {
      to: "/applications/$applicationId/terms",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/terms`,
    };
  }

  /*
   * Underwriting Related
   */
  if (
    application.stage === "risk_assessment_approved" ||
    application.stage === "underwriting_approved"
  ) {
    return {
      to: "/applications/$applicationId/terms",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/terms`,
    };
  }

  if (
    application.stage === "risk_assessment_review" ||
    application.stage === "underwriting_review"
  ) {
    return {
      to: "/applications/$applicationId/underwriting/review",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/underwriting/review`,
    };
  }

  if (
    application.stage === "risk_assessment_denied" ||
    application.stage === "underwriting_denied"
  ) {
    return {
      to: "/applications/$applicationId/underwriting/denied",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/underwriting/denied`,
    };
  }

  if (
    isXero &&
    (application.stage === "agreements_review_completed" ||
      application.stage === "loan_write_back_setup_completed")
  ) {
    return {
      to: "/applications/$applicationId/xero-writeback",
      params: {
        applicationId: application.id,
      },
      _pathname: `/applications/${application.id}/xero-writeback`,
    };
  }

  /*
   * Review Terms Related
   */
  if (
    application.stage === "terms_accepted" ||
    application.stage === "agreements_review_completed"
  ) {
    return {
      to: "/applications/$applicationId/agreements",
      params: {
        applicationId: application.id,
      },

      _pathname: `/applications/${application.id}/agreements`,
    };
  }

  /*
   * Execute Loan Related (This involves async process to complete the execute loan process)
   */
  if (
    application.stage === "execute_loan_started" ||
    application.stage === "execute_loan_completed" ||
    application.stage === "origination_started" ||
    application.stage === "origination_completed"
  ) {
    return {
      to: "/loans/$loanId",
      params: {
        loanId: application.loan_id,
      },
      _pathname: `/loans/${application.loan_id}`,
    };
  }

  /*
   * **************************
   * Fallback to /identity page
   * **************************
   */
  return {
    to: "/applications/$applicationId/identity",
    params: {
      applicationId: application.id,
    },
    _pathname: `/applications/${application.id}/identity`,
  };
}
