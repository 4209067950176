import { useAuth0 } from "@auth0/auth0-react";
import { createFileRoute } from "@tanstack/react-router";

import { Button } from "@prime/ui/src/button";

export const Route = createFileRoute("/logout/")({
  component: Page,
});

function Page() {
  const { user, logout, isAuthenticated } = useAuth0();

  if (!isAuthenticated)
    return (
      <div>
        <p>you are not authenticated. no need to logout</p>
        <pre>{JSON.stringify(user, null, 2)}</pre>
      </div>
    );

  return (
    <div>
      <pre>{JSON.stringify(user, null, 2)}</pre>
      <Button
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      >
        Logout
      </Button>
    </div>
  );
}
