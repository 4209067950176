import {
  PieChartIcon,
  ReceiptIcon,
  CreditCardIcon,
  ClockIcon,
} from "lucide-react";

import { Header } from "@prime/pop-components/src/layout";

import ScrollingPromotionCards, { PromotionCard } from "./ScrollPromotionCards";
import XeroSvg from "./xero.svg";

function XeroContainer({
  progress,
  children,
}: {
  progress: string | number;
  children: React.ReactNode;
}) {
  return (
    <div className="flex min-h-screen flex-1 flex-col items-start justify-start self-stretch overflow-hidden rounded-xl shadow-[0px_12px_48px_rgba(0,_0,_0,_0.25)]">
      <Header PartnerLogo={XeroSvg}>
        <div className="bg-surface-dark mx-6 hidden h-2 max-w-[360px] flex-1 shrink-0 flex-col items-start justify-start overflow-hidden rounded-full md:flex">
          <div
            className={`bg-surface-banner h-2 transition-all duration-500 ease-in-out`}
            style={{ width: `${progress}%` }}
          />
        </div>
      </Header>

      {children}

      <ScrollingPromotionCards>
        <PromotionCard
          Icon={PieChartIcon}
          iconColor="text-[#3364F1]"
          title="Transparent Payments"
          description="We don’t collect a percentage of your sales. No more guessing how much you’ll be paying."
        />
        <PromotionCard
          Icon={ReceiptIcon}
          iconColor="text-[#C033F1]"
          title="Fixed, Up-Front, Pricing"
          description="We don’t collect a percentage of your sales. No more guessing how much you’ll be paying."
        />
        <PromotionCard
          Icon={CreditCardIcon}
          iconColor="text-[#EF7459]"
          title="No Impact on Credit"
          description="We don’t collect a percentage of your sales. No more guessing how much you’ll be paying."
        />
        <PromotionCard
          Icon={ClockIcon}
          iconColor="text-[#63E200]"
          title="Stress Free"
          description="We don’t collect a percentage of your sales. No more guessing how much you’ll be paying."
        />
      </ScrollingPromotionCards>
    </div>
  );
}

export default XeroContainer;
