import { AppState } from "@auth0/auth0-react";
import { Auth0Provider } from "@auth0/auth0-react";
import { Router } from "@tanstack/react-router";
import React from "react";

const Auth0ProviderWithNavigate = ({
  router,
  children,
}: {
  router: Router;
  children: React.ReactNode;
}) => {
  const onRedirectCallback = (appState: AppState | undefined) => {
    router.navigate({
      to: appState?.returnTo || window.location.pathname,
      search: appState?.returnToSearch ?? {},
    });
  };

  return (
    <Auth0Provider
      domain={import.meta.env.VITE_APPLY_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_APPLY_AUTH0_CLIENT_ID}
      useCookiesForTransactions={true}
      authorizationParams={{
        redirect_uri: window.location.origin + "/authorize",
        audience: import.meta.env.VITE_APPLY_AUTH0_AUDIENCE,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
