import { createFileRoute } from "@tanstack/react-router";
import { ArrowRightIcon } from "lucide-react";

import Agreements from "@/routes/-components/agreements";
import { Header } from "@prime/pop-components/src/layout";
import { Button } from "@prime/ui/src/button";

export const Route = createFileRoute("/xero/get-started/")({
  component: Page,
});

function claimXero() {
  const redirectUri = `${window.location.origin}/authorize`;
  const applyApiUrl = import.meta.env.VITE_APPLY_API_URL;
  window.location.href = `${applyApiUrl}/auth/connection/scopes?connection=xero&redirect_uri=${redirectUri}&state={"autoClaim":true}`;
}

function Page() {
  return (
    <div className="flex min-h-screen w-screen flex-col">
      <Header />
      <div className="flex w-full flex-1 items-center justify-center p-6 text-center sm:p-12">
        <div className="flex w-full max-w-[512px] flex-col items-center gap-6 rounded-xl p-6">
          <div className="text-start">
            <div className="text-txt-primary relative inline-block w-full text-left text-2xl font-normal leading-[125%]">
              Before We Get Started
            </div>
            <div className="text-txt-tertiary z-[3] self-stretch text-base leading-[150%]">
              Please review and agree to our policies and disclosures that
              dictate how we’ll be working with you.
            </div>
          </div>

          <div className="bg-surface-inset w-full rounded-xl p-1 sm:p-3">
            <Agreements />
          </div>
          <Button
            className="mt-6 h-12 w-full rounded-xl text-base"
            onClick={claimXero}
          >
            <div className="relative font-medium leading-[24px]">
              Continue to Xero
            </div>
            <ArrowRightIcon size={24} />
          </Button>
        </div>
      </div>
    </div>
  );
}
