import * as Sentry from "@sentry/react";
import { createFileRoute, redirect } from "@tanstack/react-router";

import { getApplications, getInvitationDetails } from "@/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import { InvitationDetails } from "@/types";
import { getRouteByApplicationStage } from "@/utils";
import { Header } from "@prime/pop-components/src/layout";
import { AxiosError } from "axios";

import VerifyEmail from "../../-components/verify-email";

export const Route = createFileRoute("/invitations/$invitationId/claim/")({
  beforeLoad: async (props) => {
    const { context, params, cause } = props;
    const { auth, apiClient, unauthenticatedApiClient } = context;
    const { error, isAuthenticated } = auth;
    const { invitationId } = params;

    if (!error && isAuthenticated && cause !== "enter") {
      try {
        const { applications = [] } = await getApplications({
          apiClient,
        });

        const { to: redirectTo, params: redirectParams } =
          getRouteByApplicationStage(applications?.[0]);

        throw redirect({
          to: redirectTo,
          params: redirectParams,
          replace: true,
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          Sentry.captureException(error);
        } else {
          // This is a purposeful redirect "throw" and we don't want to catch it
          throw error;
        }
      }
    }

    try {
      const details = await getInvitationDetails({
        apiClient: unauthenticatedApiClient,
        params: {
          invitationId,
        },
      });

      return {
        details,
      };
    } catch (error) {
      const hostname = window.location.hostname;
      if (hostname === "localhost") {
        window.location.href = `http://localhost:3000/`;
      }
      window.location.href = `https://${hostname.replace("apply.", "www.")}/`;
    }

    return;
  },
  loader: async ({ context, location }) => {
    if (
      location.search &&
      "error" in location.search &&
      location.search.error === "offer_claim_failed"
    ) {
      context.toast({
        title: "There was an issue claiming your invitation.",
        description:
          "Please try again or contact support for further assistance.",
        variant: "destructive",
      });
    }
  },
  pendingComponent: LoaderScreen,
  component: Page,
});

function Page() {
  const context = Route.useRouteContext();
  const { business_name } = (context as Record<string, unknown>)
    .details as InvitationDetails;
  return (
    <div className="flex min-h-screen w-screen flex-col">
      <Header />
      <div className="flex w-full flex-1 items-center justify-center">
        <VerifyEmail business_name={business_name} />
      </div>
    </div>
  );
}
