import { ReactNode } from "@tanstack/react-router";
import { LucideIcon } from "lucide-react";
import React from "react";

export function PromotionCard({
  Icon,
  iconColor = "text-icon",
  title,
  description,
}: {
  Icon: LucideIcon;
  iconColor?: string;
  title: string;
  description: string;
}) {
  return (
    <div className="bg-txt-inverted box-border flex w-96 shrink-0 flex-row items-center justify-start gap-6 overflow-hidden rounded-lg p-3">
      <div className="bg-surface-dark box-border flex h-14 w-14 flex-col items-center justify-center rounded-md p-4">
        <Icon size={24} className={iconColor} />
      </div>
      <div className="flex flex-col items-start justify-start gap-1">
        <div className="text-txt-primary">{title}</div>
        <div className="text-txt-tertiary text-xs">{description}</div>
      </div>
    </div>
  );
}

const ScrollingPromotionCards = ({ children }: { children: ReactNode }) => {
  // Duplicate the children to create the infinite scroll effect
  const duplicatedChildren = React.Children.toArray(children)
    .concat(React.Children.toArray(children))
    .map((child: ReactNode, index) =>
      React.cloneElement(child, { key: `child-${index}` })
    );

  return (
    <div className="bg-surface-dark border-border-dark relative flex flex-row items-start justify-start self-stretch overflow-hidden border-t-[1px] border-solid py-6 pl-6 text-left text-gray-100">
      <div className="animate-scroll-content flex gap-6">
        {duplicatedChildren}
      </div>
    </div>
  );
};

export default ScrollingPromotionCards;
