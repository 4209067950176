import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useMemo } from "react";

import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";

type ApiContextValue = {
  unauthenticatedInstance: AxiosInstance;
  instance: AxiosInstance;
};
const ApiContext = createContext<ApiContextValue | null>(null);

const getAccessOptions = {
  authorizationParams: {
    audience: import.meta.env.VITE_APPLY_AUTH0_AUDIENCE,
    scope: "role:user",
  },
};

export default function ApiProvider(props: { children?: React.ReactNode }) {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const unauthenticatedInstance = axios.create({
    baseURL: import.meta.env.VITE_APPLY_API_URL,
  });

  const instance = useMemo(() => {
    const instance = axios.create({
      baseURL: import.meta.env.VITE_APPLY_API_URL,
    });

    instance.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        return await getAccessTokenSilently(getAccessOptions)
          .then((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            return config;
          })
          .catch(async () => {
            return await getAccessTokenWithPopup(getAccessOptions).then(
              (token) => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
              }
            );
          });
      },
      (error) => Promise.reject(error)
    );

    return instance;
  }, [getAccessTokenSilently, getAccessTokenWithPopup]);

  return (
    <ApiContext.Provider
      {...props}
      value={{ instance, unauthenticatedInstance }}
    />
  );
}

export function useApi() {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error(
      "You are trying to access the useApi() hook outside of the ApiContextProvider"
    );
  }
  return context.instance;
}

export function useUnauthenticatedApi() {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error(
      "You are trying to access the useUnauthenticatedApi() hook outside of the ApiContextProvider"
    );
  }
  return context.unauthenticatedInstance;
}
