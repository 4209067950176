import { createFileRoute, redirect } from "@tanstack/react-router";

import { xeroClaimOffer } from "@/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import { AccountsApplicationsResponse } from "@/types";
import { getRouteByApplicationStage } from "@/utils";
import { AxiosInstance } from "axios";

type Search = {
  statusCode?: string;
};

const claimAndRedirect = async ({
  apiClient,
}: {
  apiClient: AxiosInstance;
}) => {
  let claimOfferResponse: AccountsApplicationsResponse | undefined;

  const requested_loan_amount =
    window?.localStorage?.getItem?.("requested_loan_amount") || 50000;
  const requested_loan_term =
    window?.localStorage?.getItem?.("requested_term_length") || 3;

  try {
    claimOfferResponse = await xeroClaimOffer({
      apiClient: apiClient,
      params: {
        requested_loan_amount: Number(requested_loan_amount),
        requested_loan_term: Number(requested_loan_term),
      },
    });
  } catch (err) {
    throw redirect({
      to: "/xero/permission-failed",
    });
  }

  const { applications = [] } = claimOfferResponse || {};

  const { to: redirectTo, params: redirectParams } = getRouteByApplicationStage(
    applications?.[0]
  );

  throw redirect({
    to: redirectTo,
    params: redirectParams,
  });
};

export const Route = createFileRoute("/xero/permission-callback/")({
  pendingComponent: LoaderScreen,
  beforeLoad: async ({ context, search, cause }) => {
    if (cause !== "enter") return;
    const { statusCode = "200" } = (search || {}) as Search;
    const { apiClient, auth } = context;
    const { isAuthenticated } = auth;

    if (!isAuthenticated) {
      throw redirect({
        to: "/xero/auth",
      });
    }

    if (!String(statusCode).startsWith("2") || !apiClient) {
      throw redirect({
        to: "/xero/permission-failed",
      });
    }

    await claimAndRedirect({ apiClient });
  },
  component: Page,
});

function Page() {
  return <LoaderScreen />;
}
