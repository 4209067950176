import React from "react";

import { cn } from "../../lib/css";
import { PopLogoSVG } from "../promotional/svgs";

export default function Footer(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={cn(
        "flex w-full max-w-[1440px] flex-col items-center justify-center gap-12 overflow-hidden p-6 pt-12 text-left text-[13px] text-lg text-[hsl(var(--brand))] sm:p-12 sm:pb-6",
        props?.className
      )}
    >
      <div className="font-prime-body-large-regular text-txt-tertiary relative inline-block w-full flex-1 border-b-[1px] border-solid border-[#dee1e8] pb-12 text-left text-sm leading-[150%]">
        <p className="[margin-block-end:12px] [margin-block-start:0]">
          1 Pop is not a bank. Business-purpose loans are issued by Lead Bank
          (“Lender”). Weekly repayments will be automatically withdrawn from a
          connected business checking account. Fees may be assessed on overdue
          amounts. A loan application is required. Financing is subject to the
          Lender’s credit approval. Lenders base loan eligibility on their
          respective credit and risk policies, applicable legal requirements,
          and other business considerations. Loans may not be available in all
          states and may be subject to local restrictions.
        </p>
        <p className="[margin-block-end:0] [margin-block-start:0]">
          2 Prime Financial Technologies, Inc. is the servicer of the
          business-purpose loans.
        </p>
      </div>
      <div className="flex w-full flex-1 flex-col flex-wrap justify-between gap-6 sm:flex-row">
        <div className="flex flex-row items-center justify-center gap-2 text-center sm:items-start sm:justify-start">
          <PopLogoSVG className="w-[95px]" />
        </div>
        <div className="flex flex-col justify-center gap-6 text-xs sm:items-end">
          <div className="flex w-full flex-row justify-center gap-[24px] font-normal sm:items-start sm:justify-end">
            <div className="flex flex-row justify-center overflow-hidden sm:flex-[unset] sm:items-start">
              <a href="/docs/privacy-policy">Privacy Policy</a>
            </div>
            <div className="flex flex-row justify-center overflow-hidden sm:flex-[unset] sm:items-start">
              <a href="/docs/terms-of-service">Terms of Service</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
