import * as Sentry from "@sentry/react";
import { createFileRoute, redirect } from "@tanstack/react-router";

import { getApplications, xeroFreshClaimOffer } from "@/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import { AccountsApplicationsResponse } from "@/types";
import { getRouteByApplicationStage } from "@/utils";
import { AxiosError } from "axios";

function getAllCookies() {
  const cookies = document.cookie.split(";");
  const cookieObj: Record<string, string> = {};
  cookies.forEach((cookie) => {
    const [name, value] = cookie.split("=").map((c) => c.trim());
    cookieObj[name] = value;
  });
  return cookieObj;
}

// Users arrive at this route via a redirect from the `/authorize` route.
// They arrive here, authenticated via Auth0, looking to login to their account.
// We make a call to the API to retrieve their applications and redirect them to
// the appropriate spot in their application journey (e.g. `/applications/:id/:stage`)
export const Route = createFileRoute("/authorize/login/")({
  loader: async (props) => {
    const { context, location } = props;
    const { user: auth0User } = context.auth;

    const { connection, autoClaim = false } =
      (location.search as Record<string, unknown>) || {};

    if (!auth0User?.email) {
      throw new Error(
        "Attempting to login user via /authorize/login but no email found."
      );
    }

    let myApplicationsResponse: AccountsApplicationsResponse | undefined;

    try {
      myApplicationsResponse = await getApplications({
        apiClient: context.apiClient,
      });
    } catch (err) {
      catchBlock: {
        if (err instanceof AxiosError) {
          const status = err?.response?.status;
          // 403 is FORBIDDEN, it's thrown when the user doesn't have a Prime account yet
          // 422 is UNPROCESSABLE ENTITY, it's thrown when no applications are found for the email
          if (connection === "xero" && status === 403) {
            myApplicationsResponse = { id: "placeholder", applications: [] };
            break catchBlock;
          }
        }
        Sentry.captureException(err);

        throw redirect({
          to: "/",
          search: {
            error: "login_failed",
          },
        });
      }
    }

    const { applications = [] } = myApplicationsResponse || {};

    if (!applications.length && connection === "xero") {
      if (autoClaim) {
        const cookies = getAllCookies();
        const { requestedFunding = "20000", requestedTermLength = "3" } =
          cookies;
        const xeroClaimOfferResponse = await xeroFreshClaimOffer({
          apiClient: context.apiClient,
          params: {
            requested_loan_amount: Number(requestedFunding),
            requested_loan_term: Number(requestedTermLength),
          },
        });
        applications.push(xeroClaimOfferResponse.applications[0]);
      } else {
        throw redirect({
          to: "/xero/welcome",
        });
      }
    }

    const { to: redirectTo, params: redirectParams } =
      getRouteByApplicationStage(applications?.[0]);

    throw redirect({
      to: redirectTo,
      params: redirectParams,
    });
  },
  component: Page,
});

function Page() {
  return <LoaderScreen />;
}
