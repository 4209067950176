import { useParams, useRouteContext } from "@tanstack/react-router";
import { ArrowRightIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import * as z from "zod";
import { postMagicLinkSignUp } from "@/api";
import Agreements from "@/routes/-components/agreements";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@prime/ui/src/button";
import { DialogClose } from "@prime/ui/src/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@prime/ui/src/form";
import { Input } from "@prime/ui/src/input";

import EmailIllo from "./email-illustration.svg";

export default function VerifyEmail() {
  const { unauthenticatedApiClient, toast } = useRouteContext({
    from: "/offers/$id/",
  });
  const applicationId = useParams({
    from: "/offers/$id/",
    select: (params) => params.id,
  });

  const formSchema = z.object({
    account_email: z
      .string()
      .email({ message: "Please enter a valid email address" }),
  });

  const redirectUri = `${window.location.origin}/authorize`;

  const [processing, setProcessing] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      account_email: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const email = values.account_email;
    setProcessing(true);
    postMagicLinkSignUp({
      apiClient: unauthenticatedApiClient,
      payload: {
        email,
        redirect_uri: redirectUri,
        state: { applicationId },
      },
    })
      .then(() => {
        setEmailSent(email);
      })
      .catch(() => {
        toast({
          title: "Sign up failed",
          description: "We were unable to sign you up. Please try again.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  if (emailSent) {
    return (
      <div>
        <h3 className="text-text-900 mb-2 text-2xl font-medium">
          Check Your Inbox
        </h3>
        <p className="text-text-600 text-base font-light">
          We sent an email containing a link to sign in and view your
          application.
        </p>
      </div>
    );
  }

  const buttonDisabled = processing;

  return (
    <div className="m-auto flex max-w-lg flex-col gap-6">
      <div>
        <div className="mx-auto mb-6 h-auto w-[70px]">
          <EmailIllo />
        </div>
        <h1 className="text-text-900 mb-2 text-2xl lg:text-3xl">
          Verify Your Email
        </h1>
        <p className="text-text-600 text-base lg:text-lg lg:leading-tight">
          Please enter the business email address we will use to secure your
          account and send important messages.
        </p>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-6"
        >
          <FormField
            control={form.control}
            name="account_email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    type="email"
                    placeholder="Business Email Address"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="bg-background-option rounded-xl px-2 py-4">
            <Agreements />
          </div>
          <div className="flex flex-col gap-2">
            <Button type="submit" disabled={buttonDisabled}>
              <span>Continue and Accept Terms</span>
              <ArrowRightIcon className="ml-2 h-4 w-4" />
            </Button>
            <DialogClose asChild>
              <Button
                type="button"
                className="bg-[#38394C] hover:bg-[#38394C]/90"
              >
                Cancel
              </Button>
            </DialogClose>
          </div>
        </form>
      </Form>
    </div>
  );
}
