import { createFileRoute, redirect } from "@tanstack/react-router";

import { getXeroScopesRequest } from "@/api";
import LoaderScreen from "@/routes/-components/loader-screen";

export const Route = createFileRoute("/xero/permission-request/")({
  beforeLoad: async ({ context, cause }) => {
    if (cause !== "enter") return;
    const { auth, apiClient } = context;
    const { isAuthenticated } = auth;

    if (!isAuthenticated) {
      throw redirect({
        to: "/xero/auth",
      });
    }

    try {
      const response = await getXeroScopesRequest({
        apiClient,
        redirectUrl: `${window.location.origin}/xero/permission-callback`,
      });
      const redirectUrl = response?.data?.redirect_url;
      if (!redirectUrl) throw new Error("Redirect URL not found");
      window.location.href = redirectUrl;
    } catch (err) {
      throw redirect({
        to: "/xero/permission-failed",
      });
    }
  },
  pendingComponent: LoaderScreen,
  component: Page,
});

function Page() {
  return <LoaderScreen />;
}
