import * as SliderPrimitive from "@radix-ui/react-slider";
import * as React from "react";
import { Tally3Icon } from "lucide-react";

import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/css";

const sliderVariants = cva(
  "relative flex w-full touch-none select-none items-center",
  {
    variants: {
      variant: {
        default: "",
        xero: "",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const trackVariants = cva(
  "relative h-3 w-full grow overflow-hidden rounded-sm",
  {
    variants: {
      variant: {
        default: "bg-surface-inset",
        xero: "bg-surface-light",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const rangeVariants = cva("absolute h-full", {
  variants: {
    variant: {
      default: "bg-surface-banner",
      xero: "bg-[#1AB4D7]",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface SliderProps
  extends React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>,
    VariantProps<typeof sliderVariants>,
    VariantProps<typeof trackVariants>,
    VariantProps<typeof rangeVariants> {}

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  SliderProps
>(({ className, variant, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(sliderVariants({ variant }), className)}
    {...props}
  >
    <SliderPrimitive.Track className={trackVariants({ variant })}>
      <SliderPrimitive.Range className={rangeVariants({ variant })} />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="bg-surface-card ring-offset-background focus-visible:ring-ring border-1 flex h-6 w-9 cursor-grab items-center justify-center rounded-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
      <Tally3Icon size={16} />
    </SliderPrimitive.Thumb>
  </SliderPrimitive.Root>
));

Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
