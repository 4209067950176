import {
  AgreementReview,
  AgreementSign,
  AgreementType,
  Application,
  ApplicationUpdate,
  BankAccount,
  Borrower,
  BusinessInformation,
  BusinessPerson,
  Document,
  CreateAccountResponse,
  GetAccountResponse,
  Loan,
  OpenBankingConnectBankResponse,
  OpenBankingReconnectWidgetResponse,
  OpenBankingWidgetResponse,
  Terms,
  Transaction,
  UnderwriteResponse,
  VerificationResponse,
  CreateBusinessPersonPayload,
  UpdateBusinessPersonPayload,
  ServicingLoan,
  ServicingTransaction,
  ManualPaymentResponse,
  AccountsApplicationsResponse,
  InvitationDetails,
  WritebackOptInResponse,
  WritebackAccountsResponse,
} from "@/types";
import { AxiosInstance } from "axios";

export async function claimOffer({
  apiClient,
  payload,
}: {
  apiClient: AxiosInstance;
  payload: {
    user_email: string;
    application_id: string;
    signed_agreements: boolean;
  };
}) {
  // POST /accounts
  const res = await apiClient.post<CreateAccountResponse>(
    "/accounts/claim-offer",
    {
      ...payload,
    }
  );

  return res;
}

export async function postMagicLinkSignIn({
  apiClient,
  payload,
}: {
  apiClient: AxiosInstance;
  payload: {
    email: string;
    redirect_uri: string;
    state?: object;
  };
}) {
  const res = await apiClient.post(`/auth/magic-link/sign-in`, {
    ...payload,
  });

  return res;
}

export async function postMagicLinkSignUp({
  apiClient,
  payload,
}: {
  apiClient: AxiosInstance;
  payload: {
    email: string;
    redirect_uri: string;
    state?: object;
  };
}) {
  const res = await apiClient.post(`/auth/magic-link/sign-up`, {
    ...payload,
  });

  return res;
}

export async function getXeroScopesRequest({
  apiClient,
  redirectUrl,
}: {
  apiClient: AxiosInstance;
  redirectUrl: string;
}) {
  const res = await apiClient.get(`/auth/xero-scopes-request`, {
    params: {
      redirect_url: redirectUrl,
    },
  });
  return res;
}

export async function getAccounts({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { email: string };
}) {
  const res = await apiClient.get<GetAccountResponse>("/accounts", { params });

  return res.data;
}

export async function getApplication({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { id: string };
}) {
  const res = await apiClient.get<Application>(`/applications/${params.id}`);
  return res.data;
}

export async function getApplications({
  apiClient,
}: {
  apiClient: AxiosInstance;
}) {
  const res = await apiClient.get<AccountsApplicationsResponse>(
    "/accounts/applications"
  );

  return res.data;
}

//xero/claim-offer
export async function xeroClaimOffer({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: {
    requested_loan_amount: number;
    requested_loan_term: number;
  };
}) {
  const res = await apiClient.post<AccountsApplicationsResponse>(
    `/accounts/claim-with-xero`,
    params
  );
  return res.data;
}

//xero/claim-offer
export async function xeroFreshClaimOffer({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: {
    requested_loan_amount: number;
    requested_loan_term: number;
  };
}) {
  const res = await apiClient.post<AccountsApplicationsResponse>(
    `/accounts/fresh-with-xero`,
    params
  );
  return res.data;
}

export async function createBorrowerBusiness({
  apiClient,
  payload,
}: {
  apiClient: AxiosInstance;
  payload: BusinessInformation;
}) {
  // POST /borrowers
  const res = await apiClient.post<Borrower>(`/borrowers`, {
    name: payload.business_name,
    ein: payload.ein,
    legal_name: payload.business_name,
    address: {
      street_address_1: payload._google_address_line_1,
      street_address_2: payload._google_address_line_2,
      locality: payload._google_address_city,
      region: payload._google_address_state,
      country: payload._google_address_country_code,
      postal_code: payload._google_address_postal_code,
    },
    phones: [
      {
        country_code: "1",
        number: payload.phone,
        type: "home",
      },
    ],
    business_type: payload.business_type,
    website: payload.website,
    email: payload.email,
  });

  return res.data;
}

export async function createBusinessPerson({
  apiClient,
  payload,
}: {
  apiClient: AxiosInstance;
  payload: CreateBusinessPersonPayload;
}) {
  const isApplicant = Boolean(payload.is_applicant);
  const ownershipType = isApplicant ? "ownership_control" : "ownership";

  // POST /business_persons
  const res = await apiClient.post("/business_persons", {
    business_id: payload.business_id,
    is_applicant: isApplicant,
    is_signer: Boolean(payload.is_signer),
    first_name: payload.first_name,
    last_name: payload.last_name,
    email: payload.email,
    ssn: payload.ssn.replace(/\D/g, ""),
    date_of_birth: new Date(payload.date_of_birth).toISOString().slice(0, 10),
    address: {
      street_address_1: payload._google_address_line_1,
      street_address_2: payload._google_address_line_2,
      locality: payload._google_address_city,
      region: payload._google_address_state,
      country: payload._google_address_country_code,
      postal_code: payload._google_address_postal_code,
    },
    person_role: payload.role || "ceo",
    person_type: payload.type || "owner",
    ownership_percentage: payload.ownership_percentage,
    ownership_type: ownershipType,
    phones: [
      {
        country_code: "1",
        number: payload.phone,
        type: "home",
      },
    ],
  });

  return res.data;
}

export async function updateBusinessPerson({
  apiClient,
  params,
  payload,
}: {
  apiClient: AxiosInstance;
  params: { id: string };
  payload: UpdateBusinessPersonPayload;
}) {
  // PATCH /business_persons
  const res = await apiClient.patch(`/business_persons/${params.id}`, payload);

  return res.data;
}

export async function getBorrowerBusiness({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { borrower_id: string };
}) {
  const res = await apiClient.get<Borrower>(`/borrowers/${params.borrower_id}`);
  return res.data;
}

export async function getBusinessPersons({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { borrower_id: string };
}) {
  const res = await apiClient.get<BusinessPerson[]>(
    `/borrowers/${params.borrower_id}/business_persons`
  );
  return res.data;
}

export async function updateApplication({
  apiClient,
  params,
  payload,
}: {
  apiClient: AxiosInstance;
  params: { id: string };
  payload: ApplicationUpdate;
}) {
  // PATCH /applications/:id
  const res = await apiClient.patch(`/applications/${params.id}`, payload);

  return res.data;
}

export async function verifyApplication({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { id: string };
}) {
  // POST /applications/:id/verification
  const res = await apiClient.post<VerificationResponse>(
    `/applications/${params.id}/verification`
  );

  return res.data;
}

export async function getOpenBankingWidget({
  apiClient,
  payload,
}: {
  apiClient: AxiosInstance;
  payload: { application_id: string };
}) {
  const res = await apiClient.get<OpenBankingWidgetResponse>(
    `/applications/${payload.application_id}/ob_widget`
  );
  return res.data;
}

export async function getReconnectOpenBankingWidget({
  apiClient,
  payload,
}: {
  apiClient: AxiosInstance;
  payload: { bank_account_id: string };
}) {
  const res = await apiClient.post<OpenBankingReconnectWidgetResponse>(
    "/banking/connections/widgets/reconnection",
    {
      ...payload,
    }
  );
  return res.data;
}

export async function connectBank({
  apiClient,
  params,
  payload,
}: {
  apiClient: AxiosInstance;
  params: {
    application_id: string;
  };
  payload: {
    public_token: string;
    vendor_name: string;
  };
}) {
  const res = await apiClient.post<OpenBankingConnectBankResponse>(
    `/applications/${params.application_id}/connect_bank`,
    {
      ...payload,
    }
  );
  return res.data;
}

export async function underwriteApplication({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { application_id: string };
}) {
  const res = await apiClient.post<UnderwriteResponse>(
    `/applications/${params.application_id}/underwrite`
  );

  return res.data;
}

export async function getApplicationTerms({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { id: string };
}) {
  const res = await apiClient.get<{ terms: Terms[] }>(
    `/applications/${params.id}/terms`
  );
  return res.data;
}

export async function acceptTerms({
  apiClient,
  params,
  payload,
}: {
  apiClient: AxiosInstance;
  params: { id: string };
  payload: { terms_id: string };
}) {
  const res = await apiClient.post<Application>(
    `/applications/${params.id}/terms`,
    {
      ...payload,
    }
  );
  return res.data;
}

export async function reviewAgreement({
  apiClient,
  params,
  payload,
}: {
  apiClient: AxiosInstance;
  params: { id: string };
  payload: { agreement_type: AgreementType };
}) {
  const res = await apiClient.post<AgreementReview>(
    `/applications/${params.id}/review_agreements`,
    {
      ...payload,
    }
  );
  return res.data;
}

export async function signAgreement({
  apiClient,
  params,
  payload,
}: {
  apiClient: AxiosInstance;
  params: { id: string };
  payload: { agreement_type: AgreementType };
}) {
  const res = await apiClient.post<AgreementSign>(
    `/applications/${params.id}/agreements`,
    {
      ...payload,
    }
  );
  return res.data;
}

export async function markAgreementsCompleted({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { id: string };
}) {
  const res = await apiClient.patch<Application>(
    `/applications/${params.id}/agreements/mark_completed`
  );
  return res.data;
}

export async function executeLoan({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { id: string };
}) {
  const res = await apiClient.patch<Application>(
    `/applications/${params.id}/execute`
  );
  return res.data;
}

export async function getLoan({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { loanId: string };
}) {
  const res = await apiClient.get<Loan>(`/loans/${params.loanId}`);
  return res.data;
}

export async function getServicingLoan({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { loanId: string };
}) {
  const res = await apiClient.get<ServicingLoan>(
    `/servicing/loans/${params.loanId}`
  );
  return res.data;
}

export async function getServicingTransactions({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { loanId: string };
}) {
  const res = await apiClient.get<ServicingTransaction[]>(
    `servicing/loans/${params.loanId}/transactions`
  );
  return res.data;
}

export async function postManualPayment({
  apiClient,
  params,
  payload,
}: {
  apiClient: AxiosInstance;
  params: { loanId: string };
  payload: { amount: number; idemptotency_key?: string };
}) {
  const res = await apiClient.post<ManualPaymentResponse>(
    `/servicing/loans/${params.loanId}/manual-payment`,
    {
      ...payload,
    }
  );
  return res.data;
}

export async function getAgreements({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { loanId: string };
}) {
  const res = await apiClient.get<{ documents: Document[] }>(
    `/documents/loan/${params.loanId}`
  );
  return res.data;
}

export async function getTransactions({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { loanId: string };
}) {
  const res = await apiClient.get<Transaction[]>(
    `/transactions/loan/${params.loanId}`
  );
  return res.data;
}

export async function getBankAccount({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { borrowerId: string };
}) {
  const res = await apiClient.get<BankAccount[]>(
    `/bank_accounts/borrowers/${params.borrowerId}`
  );
  return res.data;
}

export async function getInvitationDetails({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { invitationId: string };
}) {
  const res = await apiClient.get<InvitationDetails>(
    `/invitations/invitations_with_offer_details/${params.invitationId}`
  );
  return res.data;
}

export async function getWritebackAccounts({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { applicationId: string };
}) {
  const res = await apiClient.get<WritebackAccountsResponse>(
    `/applications/${params.applicationId}/write-back/accounts?fetch=source`
  );
  return res.data;
}

export async function postWritebackOptIn({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: {
    applicationId: string;
    optIn: boolean;
    bankAccountId?: string;
    expenseAccount?: {
      nominalCode: string;
      accountName: string;
      isActive?: boolean;
      shouldCreateInPartnerSystem?: boolean;
      id?: string;
    };
  };
}) {
  const { applicationId, optIn, bankAccountId, expenseAccount } = params;

  const {
    nominalCode,
    accountName,
    isActive = true,
    shouldCreateInPartnerSystem,
    id,
  } = expenseAccount || {};

  const res = await apiClient.post<WritebackOptInResponse>(
    `/applications/${applicationId}/write-back/opt-in`,
    {
      write_back_opt_in: optIn,
      partner_platform_borrower_bank_account: bankAccountId,
      partner_platform_borrower_expense_account: expenseAccount
        ? {
            nominal_code: nominalCode,
            account_name: accountName,
            is_active: isActive,
            should_create_in_partner_system: shouldCreateInPartnerSystem,
            partner_platform_id: id,
          }
        : undefined,
    }
  );
  return res.data;
}

export async function postWritebackOptOut({
  apiClient,
  params,
}: {
  apiClient: AxiosInstance;
  params: { applicationId: string };
}) {
  return postWritebackOptIn({ apiClient, params: { ...params, optIn: false } });
}

export async function getCodatConnection({
  apiClient,
}: {
  apiClient: AxiosInstance;
}) {
  const res = await apiClient.get<Record<string, unknown>>(
    `/partner-platform-connection`
  );
  return res.data;
}

export async function disconnectCodatConnection({
  apiClient,
}: {
  apiClient: AxiosInstance;
}) {
  const res = await apiClient.delete<Record<string, unknown>>(
    `/partner-platform-connection`
  );
  return res.data;
}
