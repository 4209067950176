import {
  Outlet,
  createFileRoute,
  defer,
  redirect,
} from "@tanstack/react-router";
import { LogOutIcon, Menu } from "lucide-react";
import {
  CircleCheckIcon,
  ClipboardCheckIcon,
  FilePenLineIcon,
  LandmarkIcon,
  LucideIcon,
  PenSquareIcon,
  ShieldCheckIcon,
  SquareUserIcon,
  UsersIcon,
} from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { getApplication, getApplicationTerms } from "@/api";
import { Application, Terms } from "@/types";
import { getRouteByApplicationStage } from "@/utils";
import { PopLogoSVG } from "@prime/pop-components/src/promotional/svgs";
import { cn } from "@prime/ui/lib/css";
import { Button } from "@prime/ui/src/button";
import {
  Portal,
  PortalAside,
  PortalAsideNavigation,
  PortalAsideNavigationItemState,
  PortalContent,
  PortalHeader,
  PortalMain,
} from "@prime/ui/src/portal";
import { Sheet, SheetContent, SheetTrigger } from "@prime/ui/src/sheet";
import { Skeleton } from "@prime/ui/src/skeleton";

import ApplicationContext from "./-applicationContext";

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout"
)({
  beforeLoad: async ({ context, params }) => {
    const { auth, apiClient } = context;
    const { isAuthenticated } = auth;

    if (!isAuthenticated) {
      throw redirect({
        to: "/",
        search: {
          error: "authentication_required",
        },
      });
    }

    // Fetch the application (using the $applicationId URL param)
    const application = await getApplication({
      apiClient,
      params: { id: params.applicationId },
    });

    const terms = getApplicationTerms({
      apiClient,
      params: { id: application.id },
    });

    const {
      to: redirectTo,
      params: redirectParams,
      _pathname: redirectPathname,
    } = getRouteByApplicationStage(application);

    const currentRoute = location.pathname;

    // If the current route is not the redirect route, redirect to the correct route
    if (currentRoute !== redirectPathname) {
      throw redirect({
        to: redirectTo,
        params: redirectParams,
        replace: true,
      });
    } else {
      // Expose the application object to any child pages via context (e.g. Route.useRouteContext() or context in beforeLoad/loader functions)
      return { application, terms: defer(terms) };
    }
  },
  component: Layout,
  pendingComponent: PendingLayout,
  errorComponent: ErrorLayout,
});

const getIdentityNavigationState = (
  application: Application
): PortalAsideNavigationItemState => {
  switch (application.stage) {
    case "offer_claimed":
    case "offer_generated":
      return "active";
    default:
      return "complete";
  }
};

const getOwnershipNavigationState = (
  application: Application
): PortalAsideNavigationItemState => {
  switch (application.stage) {
    case "identification_completed":
      return "active";
    case "offer_claimed":
    case "offer_generated":
      return "incomplete";
    default:
      return "complete";
  }
};

const getConnectBankNavigationState = (
  application: Application
): PortalAsideNavigationItemState => {
  switch (application.stage) {
    case "verification_approved":
    case "connect_bank_started":
    case "connect_bank_completed":
    case "payment_provider_borrower_setup_completed":
      return "active";
    case "underwriting_approved":
    case "underwriting_review":
    case "underwriting_denied":
    case "risk_assessment_approved":
    case "risk_assessment_review":
    case "risk_assessment_denied":
    case "terms_accepted":
    case "agreements_review_completed":
    case "execute_loan_completed":
    case "execute_loan_started":
    case "payment_provider_borrower_bank_account_setup_completed":
      return "complete";
    default:
      return "incomplete";
  }
};

const getTermsNavigationState = (
  application: Application
): PortalAsideNavigationItemState => {
  switch (application.stage) {
    case "underwriting_approved":
    case "payment_provider_borrower_bank_account_setup_completed":
    case "risk_assessment_approved":
      return "active";
    case "terms_accepted":
    case "agreements_review_completed":
    case "execute_loan_completed":
    case "execute_loan_started":
      return "complete";
    default:
      return "incomplete";
  }
};

const getSignAgreementsNavigationState = (
  application: Application
): PortalAsideNavigationItemState => {
  const isXero = application?.partner_short_id === "xero";

  switch (application.stage) {
    case "terms_accepted":
      return "active";
    case "agreements_review_completed":
      // Shouldn't happen, but just in case...
      if (!isXero) {
        return "active";
      }
      return "complete";
    default:
      return "incomplete";
  }
};

const getXeroNavigationState = (
  application: Application
): PortalAsideNavigationItemState => {
  switch (application.stage) {
    case "agreements_review_completed":
    case "loan_write_back_setup_completed": // Keving don't ask me why...we need a new stage -.-
      return "active";
    default:
      return "incomplete";
  }
};

type Step = {
  IconComponent: LucideIcon;
  title: string;
  state: PortalAsideNavigationItemState;
};

const PortalAsideNavigationItem = ({
  className,
  step,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  step: Step;
}) => {
  const { IconComponent, title, state } = step;
  return (
    <div
      className={cn(
        "text-text-sidebar flex items-center justify-between rounded-lg px-2 py-2.5 text-sm font-normal",
        state === "active" && "bg-surface-card text-[hsl(var(--brand))]",
        className
      )}
      {...props}
    >
      <div className="flex gap-3">
        <IconComponent
          className={cn(
            "h-6 w-6",
            state === "complete" && "text-accent-repaid"
          )}
        />
        <span className={cn(state === "complete" && "text-accent-repaid")}>
          {title}
        </span>
      </div>
      {state === "complete" && (
        <CircleCheckIcon className="text-accent-repaid ml-auto h-5 w-5" />
      )}
    </div>
  );
};

const getInitialSteps = (application: Application): Step[] => {
  const isXero = application?.partner_short_id === "xero";
  return [
    {
      IconComponent: ShieldCheckIcon,
      title: "Create Account",
      state: "complete",
    },
    {
      IconComponent: SquareUserIcon,
      title: "Identification",
      state: getIdentityNavigationState(application),
    },
    {
      IconComponent: UsersIcon,
      title: "Ownership",
      state: getOwnershipNavigationState(application),
    },
    {
      IconComponent: LandmarkIcon,
      title: "Connect Bank",
      state: getConnectBankNavigationState(application),
    },
    {
      IconComponent: ClipboardCheckIcon,
      title: "Accept Terms",
      state: getTermsNavigationState(application),
    },
    {
      IconComponent: PenSquareIcon,
      title: "Sign Agreements",
      state: getSignAgreementsNavigationState(application),
    },
    ...((isXero
      ? [
          {
            IconComponent: FilePenLineIcon,
            title: "Xero Writeback",
            state: getXeroNavigationState(application),
          },
        ]
      : []) as Step[]),
  ];
};

function Layout() {
  const context = Route.useRouteContext();
  const { auth, application, terms } = context || {};
  const { user, logout } = auth;
  const [termsData, setTermsData] = useState<Terms>();

  useEffect(() => {
    terms.then(({ terms: t }) => setTermsData(t?.[0]));
  }, [terms, setTermsData]);

  const [steps, setSteps] = useState<Step[]>(getInitialSteps(application));

  const activeStepIndex = useMemo(
    () => steps.findIndex((step) => step.state === "active"),
    [steps]
  );
  const activeStep = useMemo(
    () => steps?.[activeStepIndex],
    [steps, activeStepIndex]
  );

  const nextStep = useCallback(() => {
    setSteps(
      steps.map((step, index) => {
        if (index === activeStepIndex) {
          return { ...step, state: "complete" };
        } else if (index === activeStepIndex + 1) {
          return { ...step, state: "active" };
        } else {
          return step;
        }
      })
    );
  }, [steps, activeStepIndex, setSteps]);

  const refresh = useCallback(() => {
    setSteps(getInitialSteps(application));
  }, [application, setSteps]);

  useEffect(() => {
    refresh();
  }, [application, refresh]);

  if (!user) return null;

  const header = activeStep ? (
    <span className="flex items-center gap-2">
      <activeStep.IconComponent className="h-6 w-6" />
      {activeStep.title}
    </span>
  ) : (
    <span className="h-6"></span>
  );

  function SidebarContent() {
    return (
      <>
        <div>
          <div>
            <div className="grid-col border-b-txt-inverted-dimmed grid gap-1.5 border-b pb-6">
              <PopLogoSVG color="white" className="h-9" />
            </div>

            <div className="border-b-txt-inverted-dimmed border-b py-6">
              <p className="text-lg font-medium tracking-wider text-white">
                Term Loan
              </p>
              {termsData ? (
                <p className="text-txt-inverted mt-1 text-xs font-medium uppercase tracking-wider opacity-[0.7]">
                  ${Math.floor(Number(termsData.loan_amount)).toLocaleString()}{" "}
                  •{" "}
                  {Math.floor(
                    (termsData.total_number_of_payments *
                      termsData.remittance_frequency) /
                      30
                  )}{" "}
                  MONTHS
                </p>
              ) : null}
            </div>
          </div>
          <PortalAsideNavigation className="mt-6">
            {steps.map((step) => {
              return <PortalAsideNavigationItem key={step.title} step={step} />;
            })}
          </PortalAsideNavigation>
        </div>
        <footer className="flex flex-col gap-6">
          <div className="flex items-center justify-between rounded-xl p-3">
            <Button
              className="flex gap-2"
              size="sm"
              variant="ghost"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              <LogOutIcon /> Sign Out
            </Button>
          </div>
        </footer>
      </>
    );
  }

  return (
    <ApplicationContext.Provider
      value={{ steps, setSteps, nextStep, activeStep, refresh }}
    >
      <Portal>
        <PortalAside>
          <SidebarContent />
        </PortalAside>
        <PortalMain className="flex flex-col overflow-hidden bg-[hsl(var(--gray-100))] md:rounded-xl">
          <PortalHeader>
            {header}
            <Sheet>
              <SheetTrigger asChild>
                <Button
                  variant="icon"
                  size="icon"
                  className="ml-auto shrink-0 md:hidden"
                >
                  <Menu className="h-5 w-5" />
                  <span className="sr-only">Toggle navigation menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent
                side="right"
                className="text-text-sidebar bg-surface-banner flex flex-col"
              >
                <SidebarContent />
              </SheetContent>
            </Sheet>
          </PortalHeader>
          <PortalContent className="flex flex-1 flex-col items-center overflow-auto">
            <div className="flex w-full flex-1 flex-col items-center gap-6 p-4">
              <Outlet />
            </div>
          </PortalContent>
        </PortalMain>
      </Portal>
    </ApplicationContext.Provider>
  );
}

function ErrorLayout() {
  return (
    <div className="m-auto flex max-w-lg flex-col gap-6 p-6">
      <div className="rounded-xl border border-red-500 p-8">
        <h1 className="text-readonly text-2xl font-extralight">
          We&rsquo;re sorry, but there seems to be an error in your application.
          We are working to fix it.
        </h1>
      </div>
    </div>
  );
}

function PendingLayout() {
  return (
    <Portal>
      <PortalAside>
        <PortalAsideNavigation>
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
        </PortalAsideNavigation>
      </PortalAside>
      <PortalMain>
        <PortalHeader>
          <Skeleton className="h-3 w-full" />
        </PortalHeader>
        <PortalContent>
          <Skeleton className="h-20 w-full" />
          <Skeleton className="mt-10 h-40 w-full" />
        </PortalContent>
      </PortalMain>
    </Portal>
  );
}
