import * as Sentry from "@sentry/react";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { BadgeCheckIcon } from "lucide-react";
import { useCallback, useState } from "react";

import { getApplications, getInvitationDetails } from "@/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import { InvitationDetails, LoanType } from "@/types";
import { getRouteByApplicationStage } from "@/utils";
import useSmoothAnchors from "@prime/pop-components/src/hooks/useSmoothAnchors";
import { Footer, Header } from "@prime/pop-components/src/layout";
import { Guide, PromoTerms } from "@prime/pop-components/src/promotional";
import { FinancingDontSuckTextSVG } from "@prime/pop-components/src/promotional/svgs";
import { Button } from "@prime/ui/src/button";
import { AxiosError } from "axios";

export const Route = createFileRoute("/invitations/$invitationId/")({
  beforeLoad: async (props) => {
    const { context, params, cause } = props;
    const { auth, apiClient, unauthenticatedApiClient } = context;
    const { error, isAuthenticated } = auth;
    const { invitationId } = params;

    if (!error && isAuthenticated && cause !== "enter") {
      try {
        const { applications = [] } = await getApplications({
          apiClient,
        });

        const { to: redirectTo, params: redirectParams } =
          getRouteByApplicationStage(applications?.[0]);

        throw redirect({
          to: redirectTo,
          params: redirectParams,
          replace: true,
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          Sentry.captureException(error);
        } else {
          // This is a purposeful redirect "throw" and we don't want to catch it
          throw error;
        }
      }
    }

    try {
      const details = await getInvitationDetails({
        apiClient: unauthenticatedApiClient,
        params: {
          invitationId,
        },
      });

      console.log(details);

      return {
        details,
      };
    } catch (error) {
      const hostname = window.location.hostname;
      if (hostname === "localhost") {
        window.location.href = `http://localhost:3000/`;
        return {
          application: null,
        };
      }
      window.location.href = `https://${hostname.replace("apply.", "www.")}/`;
    }

    return {
      details: null,
    };
  },
  component: Page,
  pendingComponent: LoaderScreen,
});

function Hero() {
  const [step, setStep] = useState(0);
  const navigate = Route.useNavigate();
  const params = Route.useParams();

  const context = Route.useRouteContext();
  const {
    term_length = 91,
    loan_amount,
    loan_total_amount,
    apr,
    business_name,
    expiration,
    weekly_payment,
    loan_type,
    origination_fee_amount,
    claimed = false,
  } = ((context as Record<string, unknown>).details || {}) as InvitationDetails;

  const getApproved = useCallback(() => {
    navigate({
      to: "/invitations/$invitationId/claim",
      params,
    });
  }, [params, navigate]);

  const signIn = useCallback(() => {
    window.location.href = "/";
    // For some strange reason navigate renders the index route the first time without context
    // navigate({
    //   to: "/",
    // });
  }, []);

  const Section2 = (
    <>
      <div className="border-gainsboro flex flex-col items-start justify-start self-stretch overflow-hidden rounded-b-none rounded-t-xl border-b-[1px] border-solid px-6 py-4">
        <div className="text-icon flex flex-row items-center justify-start self-stretch font-medium">
          <div className="flex flex-1 flex-row items-center justify-center gap-[8px] uppercase">
            <BadgeCheckIcon size={21} />
            <p className="uppercase">Prequalified Business Loan</p>
          </div>
        </div>
      </div>
      <div className="text-txt-secondary flex flex-1 flex-col items-start justify-between gap-12 self-stretch overflow-hidden rounded-b-xl rounded-t-none p-6 text-sm">
        <div className="w-full">
          <div className="flex flex-col items-center justify-center gap-[6px] self-stretch rounded-lg">
            <div className="relative font-normal leading-[150%]">
              Loan Amount
            </div>
            <div className="border-border-dark box-border flex h-[65px] flex-row items-center justify-center gap-[12px] self-stretch rounded-md border-[1px] border-solid px-3 py-0 text-left text-[24px] text-black">
              <div className="flex h-10 flex-row items-center justify-start gap-[3px]">
                <div className="relative opacity-[0.5]">$</div>
                <div className="relative text-[36px]">
                  {Number(loan_amount).toLocaleString("en-US")}
                </div>
              </div>
              <div className="border-icon-default box-border hidden h-10 w-10 flex-row items-center justify-center rounded-md border-[1.5px] border-solid">
                <img className="relative h-4 w-4" alt="" src="Group 9.svg" />
              </div>
            </div>
          </div>
          <div className="border-whitesmoke-100 flex flex-row items-center justify-between self-stretch border-b-[1px] border-solid px-1.5 py-4">
            <div className="relative leading-[150%]">Loan Duration</div>
            <div className="relative text-left">
              {Math.round(term_length / 30)} Months
            </div>
          </div>
          <div className="flex flex-row items-center justify-between self-stretch border-b-[1px] border-solid px-1.5 py-4">
            <div className="relative leading-[150%]">Weekly Payment</div>
            <div className="relative text-left">
              ${Number(weekly_payment).toLocaleString("en-US")}
            </div>
          </div>
          <div className="border-whitesmoke-100 flex flex-row items-center justify-between self-stretch border-b-[1px] border-solid px-1.5 py-4">
            <div className="relative leading-[150%]">Total Cost</div>
            <div className="relative text-left">
              ${Number(loan_total_amount).toLocaleString("en-US")}
            </div>
          </div>
          {loan_type !== LoanType.OriginationFeeNoInterest ? (
            <div className="border-whitesmoke-100 flex flex-row items-center justify-between self-stretch px-1.5 py-4">
              <div className="relative leading-[150%]">Effective APR</div>
              <div className="relative text-left">{apr}%</div>
            </div>
          ) : (
            <div className="border-whitesmoke-100 flex flex-row items-center justify-between self-stretch px-1.5 py-4">
              <div className="relative leading-[150%]">Origination Fee</div>
              <div className="relative text-left">
                ${origination_fee_amount}
              </div>
            </div>
          )}
        </div>
        {!claimed ? (
          <Button
            className={`h-12 w-full rounded-xl text-base`}
            variant={"gradient"}
            onClick={getApproved}
          >
            <div className="relative font-medium leading-[24px]">Apply Now</div>
          </Button>
        ) : (
          <Button
            className={`h-12 w-full rounded-xl text-base`}
            variant={"gradient"}
            onClick={signIn}
          >
            <div className="relative font-medium leading-[24px]">Sign In</div>
          </Button>
        )}
      </div>
    </>
  );

  return (
    <div className="m-12 flex min-h-[480px] w-full max-w-[380px] flex-row items-center justify-start overflow-hidden rounded-xl text-center shadow-[0px_0px_48px_rgba(0,_0,_0,_0.05)] transition-transform duration-500 md:m-[120px] md:w-full md:max-w-[840px]">
      {!step ? (
        <>
          <div className="box-border flex flex-1 flex-col items-start justify-start gap-[45px] self-stretch rounded-l-xl rounded-r-xl bg-[hsl(var(--gray-100)/0.3)] bg-white bg-opacity-50 p-6 [backdrop-filter:blur(32px)] md:min-w-[360px] md:rounded-r-[0]">
            <div className="flex flex-1 flex-col items-start justify-start gap-[24px] self-stretch text-left">
              <div className="relative flex flex-1 flex-col items-start justify-between self-stretch">
                <p className="text-btn text-xl font-medium uppercase">
                  {business_name}
                </p>
                <p className="text-txt-primary my-3 text-[40px] leading-[125%] md:text-[42px]">
                  You’re pre-qualified for{" "}
                  <span className="rounded-md px-3 [background:linear-gradient(rgba(255,_255,_255,_0.75),_rgba(255,_255,_255,_0.75)),_linear-gradient(90.05deg,_#8959ef_0.5%,_#595fef_47.98%,_#1ab4d7)]">
                    <span className="!bg-clip-text text-transparent [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] [background:linear-gradient(90.05deg,_#8959ef_0.5%,_#595fef_47.98%,_#1ab4d7)]">
                      ${Number(loan_amount).toLocaleString("en-US")}
                    </span>
                  </span>{" "}
                  of fast funding.
                </p>

                <div className="text-txt-primary relative z-[3] mb-6 self-stretch text-base leading-[150%]">
                  Congratulations! You’ve been pre-qualified for exclusive
                  funding tailored to your business needs. Click the button on
                  the right to get instant approval. No paperwork, no hassle –
                  secure your funding now and take your business to the next
                  level!
                </div>
                <Button
                  variant={"gradient"}
                  className="mb-3 mt-6 flex h-12 w-full rounded-xl text-base md:hidden"
                  onClick={() => setStep(1)}
                >
                  <div className="relative font-medium leading-[24px]">
                    View your offer
                  </div>
                </Button>
                {/* <div className="flex w-full flex-1 flex-col"> */}
                <div className="mt-auto flex w-full flex-row items-center justify-center self-start overflow-hidden rounded-md border-[1.5px] border-solid border-[hsl(var(--brand))] p-2 text-center text-xs uppercase text-[hsl(var(--brand))] md:w-[unset] md:justify-start">
                  {!claimed ? (
                    <>
                      OFFER EXPIRES{" "}
                      {new Date(expiration)
                        .toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                        .toUpperCase()}
                    </>
                  ) : (
                    <>OFFER CLAIMED</>
                  )}
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="bg-surface-card box-border hidden flex-1 flex-col items-start justify-center self-stretch overflow-hidden rounded-r-xl border-l-[1px] border-solid bg-white text-left text-sm md:flex md:min-w-[312px]">
            {Section2}
          </div>
        </>
      ) : (
        <div className="bg-surface-card box-border flex flex-1 flex-col items-start justify-center self-stretch overflow-hidden rounded-r-xl border-l-[1px] border-solid bg-white text-left text-sm md:min-w-[312px]">
          {Section2}
        </div>
      )}
    </div>
  );
}

function Page() {
  useSmoothAnchors();
  return (
    <div className="flex flex-1 flex-col items-center">
      <div className="flex w-full max-w-[1440px] flex-1 flex-col">
        <Header className="border-0">
          <div className="hidden flex-row items-center justify-start gap-6 p-3 text-center text-base md:flex">
            <div className="flex flex-row items-center justify-center overflow-hidden rounded-3xl px-[18px] py-1.5">
              <a
                href="#guide"
                className="relative leading-[150%] text-[hsl(var(--brand))]"
              >
                Borrower Guide
              </a>
            </div>
          </div>
        </Header>
        <div className="m-0 flex flex-1 flex-col items-center justify-center overflow-hidden bg-[url(/assets/campaign-bg.png)] bg-cover bg-no-repeat shadow-lg md:m-3 md:mb-0 md:rounded-xl">
          <Hero />
        </div>
        <div className="flex flex-1 flex-col items-center justify-center p-0 md:p-3 md:pt-12">
          <div className="flex h-[919px] w-full max-w-[432px] flex-col items-center justify-between p-6 pb-0 lg:h-[636px] lg:max-w-[960px] lg:flex-row lg:justify-center lg:gap-[60px] lg:p-[60px] lg:pb-[60px]">
            <FinancingDontSuckTextSVG className="text-[3rem] sm:text-[3.75rem]" />
            <img src="/assets/campaign-phone.png" className="w-[371px]" />
          </div>

          <div className="w-full p-0 md:pb-12">
            <PromoTerms />
          </div>
          <Guide />
        </div>
        <Footer />
      </div>
    </div>
  );
}
