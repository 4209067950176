import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useCallback } from "react";

import LoaderScreen from "@/routes/-components/loader-screen";
import { Button } from "@prime/ui/src/button";
import { Card, CardContent, CardHeader } from "@prime/ui/src/card";

import XeroContainer from "../-components/Container";

export const Route = createFileRoute("/xero/permission-failed/")({
  pendingComponent: LoaderScreen,
  component: Page,
});

function Page() {
  const navigate = useNavigate();

  const getXeroUserPermission = useCallback(() => {
    navigate({ to: "/xero/permission-request" });
  }, [navigate]);

  return (
    <XeroContainer progress={75}>
      <div className="flex w-full flex-1 flex-col items-center justify-center">
        <Card className="max-w-[512px]">
          <CardHeader className="font-medium">
            Connection Unsuccessful
          </CardHeader>
          <CardContent className="flex flex-col">
            <div className="text-txt-tertiary mb-6 self-stretch text-base leading-[150%]">{`Xero's Permission request failed. Please try again.`}</div>
            <Button className="mb-3 w-full" onClick={getXeroUserPermission}>
              Retry
            </Button>
            <div className="text-txt-tertiary text-center text-xs leading-[150%]">
              {`Having trouble? `}
              <a href="mailto:support@primeft.com">Contact Support</a>
            </div>
          </CardContent>
        </Card>
      </div>
    </XeroContainer>
  );
}
