import * as Sentry from "@sentry/react";
import { createFileRoute, redirect } from "@tanstack/react-router";

import { claimOffer, getApplications } from "@/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import { CreateAccountResponse } from "@/types";
import { getRouteByApplicationStage } from "@/utils";
import { AxiosError, AxiosResponse } from "axios";

function redirectHome() {
  const hostname = window.location.hostname;
  if (hostname === "localhost") {
    window.location.href = `http://localhost:3000/`;
    return {
      application: null,
    };
  }
  window.location.href = `https://${hostname.replace("apply.", "www.")}/`;
}

// Users arrive at this route via a redirect from the `/authorize` route.
// They arrive here, authenticated via Auth0, looking to claim an offer.
// We create a new account for them, sign the necessary legal agreements,
// and redirect them to their application
export const Route = createFileRoute("/authorize/claim/")({
  pendingComponent: LoaderScreen,
  beforeLoad: async (props) => {
    const { context, location, cause } = props;
    if (cause !== "enter") return;

    const { user: auth0User, isAuthenticated } = context.auth;

    const applicationId =
      ((location.search as Record<string, unknown>)?.applicationId as string) ||
      undefined;

    // If authenticated check if the user has an application
    if (isAuthenticated && auth0User?.email) {
      try {
        const { applications = [] } = await getApplications({
          apiClient: context.apiClient,
        });

        if (applications?.length) {
          Sentry.captureException(
            `Not a big deal, but this user got here with an existing application. User: ${auth0User?.email} with application ${applications[0].id} tried to claim ${applicationId || "No ApplicationId"}`
          );
          const { to: redirectTo, params: redirectParams } =
            getRouteByApplicationStage(applications?.[0]);

          throw redirect({
            to: redirectTo,
            params: redirectParams,
            replace: true,
          });
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
        } else {
          // This is a purposeful redirect "throw" and we don't want to catch it
          throw error;
        }
      }
    } else {
      Sentry.captureException(
        `User should be authenticated to claim an offer. ${applicationId || "No ApplicationId"}`
      );
      if (applicationId) {
        throw redirect({
          to: "/invitations/$invitationId/claim",
          params: {
            invitationId: applicationId,
          },
          search: {
            error: "offer_claim_failed",
          },
        });
      }
      return redirectHome();
    }

    if (!applicationId) {
      Sentry.captureException(
        `How did you get here without an applicationId? ${auth0User?.sub}`
      );
      return redirectHome();
    }

    let createAccountRes:
      | AxiosResponse<CreateAccountResponse, AxiosError>
      | undefined;

    try {
      createAccountRes = await claimOffer({
        apiClient: context.apiClient,
        payload: {
          user_email: auth0User.email,
          application_id: applicationId,
          signed_agreements: true,
        },
      });
    } catch (err) {
      Sentry.captureException(err);

      if (err instanceof AxiosError) {
        if (err?.response?.status === 412) {
          throw redirect({
            to: `/offers/$id/claimed`,
            params: {
              id: applicationId,
            },
          });
        }
      }

      throw redirect({
        to: "/invitations/$invitationId/claim",
        params: {
          invitationId: applicationId,
        },
        search: {
          error: "offer_claim_failed",
          email: auth0User.email,
        },
      });
    }

    if (createAccountRes?.data) {
      throw redirect({
        to: `/applications/$applicationId/identity`,
        params: {
          applicationId: createAccountRes.data.application.id,
        },
      });
    } else {
      throw redirect({
        to: "/invitations/$invitationId/claim",
        params: {
          invitationId: applicationId,
        },
        search: {
          error: "offer_claim_failed",
          email: auth0User.email,
        },
      });
    }
  },
  component: Page,
});

function Page() {
  return <LoaderScreen />;
}
