import React, { createContext, useContext, useState } from "react";

import { Application, BusinessType } from "@/types";
import { maskEIN, maskPhone } from "@prime/ui/lib/masks";

import {
  type PersonalInformation,
  type BusinessInformation,
} from "../lib/schema";

type Step = "personal" | "business" | "review";

type IdentityContextValue = {
  step: Step;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
  personalInformation: PersonalInformation;
  setPersonalInformation: React.Dispatch<
    React.SetStateAction<PersonalInformation>
  >;
  businessInformation: BusinessInformation;
  setBusinessInformation: React.Dispatch<
    React.SetStateAction<BusinessInformation>
  >;
  googlePlacesApiKey: string;
  isSubmitting: boolean;
  onSubmit: ({
    personalInformation,
    businessInformation,
  }: {
    personalInformation: PersonalInformation;
    businessInformation: BusinessInformation;
  }) => Promise<void>;
};

const IdentityContext = createContext<IdentityContextValue | null>(null);

const getInitialPI = (
  application?: Application,
  userEmail?: string
): PersonalInformation => {
  const isXero = application?.partner_short_id === "xero";
  if (isXero) {
    const xeroOrg =
      (
        application?.additional_metadata?.xero_organization as {
          Organisations: any[];
        }
      )?.Organisations?.[0] || {};
    const xeroUsers =
      (
        application?.additional_metadata?.xero_users as {
          Users: any[];
        }
      )?.Users || [];
    const user = xeroUsers.find(
      (user: { EmailAddress: string }) => user.EmailAddress === userEmail
    );

    const cell_phone = xeroOrg?.Phones?.find(
      (phone: { PhoneType: string }) => phone.PhoneType === "MOBILE"
    )?.PhoneNumber;

    return {
      first_name: user?.FirstName || "",
      last_name: user?.LastName || "",
      address_search: "",
      _google_address_line_1: "",
      _google_address_line_2: "",
      _google_address_city: "",
      _google_address_state: "",
      _google_address_postal_code: "",
      _google_address_country_code: "",
      role: "" as "ceo", // Empty string as default value for type checking
      type: user?.IsSubscriber ? "owner" : ("" as "owner"), // Empty string as default value for type checking
      date_of_birth: "",
      ssn: "",
      phone: cell_phone ? maskPhone(cell_phone) : "",
      email: userEmail || "",
    };
  }
  return {
    first_name: "",
    last_name: "",
    address_search: "",
    _google_address_line_1: "",
    _google_address_line_2: "",
    _google_address_city: "",
    _google_address_state: "",
    _google_address_postal_code: "",
    _google_address_country_code: "",
    role: "" as "ceo", // Empty string as default value for type checking
    type: "" as "owner", // Empty string as default value for type checking
    date_of_birth: "",
    ssn: "",
    phone: "",
    email: "",
  };
};

const XERO_BUSINESS_TYPE_MAP: Record<string, BusinessType> = {
  ACCOUNTING_PRACTICE: "other",
  CHARITY: "other",
  CLUB_OR_SOCIETY: "cooperative",
  COMPANY: "corporation", // or "llc" if structured as an LLC
  INDIVIDUAL: "llc", // if structured as a single-member LLC
  NOT_FOR_PROFIT: "other",
  PARTNERSHIP: "partnership",
  S_CORPORATION: "corporation",
  SOLE_TRADER: "sole_proprietorship",
  TRUST: "trust",

  // Non-relevant to the US
  LOOK_THROUGH_COMPANY: "" as "llc", // Specific to New Zealand tax law
  SELF_MANAGED_SUPERANNUATION_FUND: "" as "llc", // Specific to Australia
  SUPERANNUATION_FUND: "" as "llc", // Specific to Australia
};

const getInitialBI = (
  application?: Application,
  userEmail?: string
): BusinessInformation => {
  const isXero = application?.partner_short_id === "xero";
  if (isXero) {
    const xeroOrg =
      (
        application?.additional_metadata?.xero_organization as {
          Organisations: any[];
        }
      )?.Organisations?.[0] || {};

    const website = xeroOrg?.ExternalLinks?.find?.(
      (link: { LinkType: string }) => link.LinkType === "Website"
    )?.Url;

    const office_phone =
      xeroOrg?.Phones?.find?.(
        (phone: { PhoneType: string }) => phone.PhoneType === "OFFICE"
      )?.PhoneNumber || xeroOrg?.Phones?.[0]?.PhoneNumber;

    const address = xeroOrg?.Addresses?.find?.(
      (address: { AddressType: string; Country: string }) =>
        address.AddressType === "STREET" && address.Country === "United States"
    );

    const addressSearch = address
      ? `${address?.AddressLine1 || ""}, ${
          address?.City || ""
        }, ${address?.Region || ""} ${address?.PostalCode}`
      : "";

    const ein = xeroOrg?.EmployerIdentificationNumber;

    return {
      business_name: xeroOrg?.LegalName || xeroOrg?.Name || "",
      business_type:
        XERO_BUSINESS_TYPE_MAP[xeroOrg?.OrganisationType] || ("" as "llc"), // Empty string as default value for type checking
      address_search: addressSearch,
      _google_address_line_1: address?.AddressLine1 || "",
      _google_address_line_2: "",
      _google_address_city: address?.City || "",
      _google_address_state: address?.Region || "",
      _google_address_postal_code: address?.PostalCode || "",
      _google_address_country_code: "US",
      phone: office_phone ? maskPhone(office_phone) : "",
      ein: ein ? maskEIN(ein) : "",
      email: userEmail || "",
      website: website || "",
      ownership_percentage: "",
    };
  }
  return {
    business_name: "",
    business_type: "" as "llc", // Empty string as default value for type checking
    address_search: "",
    _google_address_line_1: "",
    _google_address_line_2: "",
    _google_address_city: "",
    _google_address_state: "",
    _google_address_postal_code: "",
    _google_address_country_code: "",
    phone: "",
    ein: "",
    email: "",
    website: "",
    ownership_percentage: "",
  };
};

function IdentityContextProvider(props: {
  googlePlacesApiKey: string;
  isSubmitting: boolean;
  onSubmit: ({
    personalInformation,
    businessInformation,
  }: {
    personalInformation: PersonalInformation;
    businessInformation: BusinessInformation;
  }) => Promise<void>;
  children?: React.ReactNode;
  application?: Application;
  userEmail?: string;
}) {
  const [step, setStep] = useState<Step>("personal");

  const [personalInformation, setPersonalInformation] =
    useState<PersonalInformation>(
      getInitialPI(props?.application, props?.userEmail)
    );

  const [businessInformation, setBusinessInformation] =
    useState<BusinessInformation>(
      getInitialBI(props?.application, props?.userEmail)
    );

  const contextValue = {
    step,
    setStep,
    personalInformation,
    setPersonalInformation,
    businessInformation,
    setBusinessInformation,
    googlePlacesApiKey: props.googlePlacesApiKey,
    isSubmitting: props.isSubmitting,
    onSubmit: props.onSubmit,
  };
  return <IdentityContext.Provider {...props} value={contextValue} />;
}

function useIdentityContext() {
  const context = useContext(IdentityContext);

  if (!context) {
    throw new Error(
      "You are trying to use `useIdentityContext` but the calling site is not a child of the <IdentityContextProvider> component"
    );
  }
  return context;
}

export { useIdentityContext, IdentityContextProvider };
