import { ArrowLeftRightIcon } from "lucide-react";
import React from "react";

import { cn } from "../../lib/css";
import { PopLogoSVG, PopLogoWithTextSVG } from "../promotional/svgs";

export default function Header(
  props: React.HTMLAttributes<HTMLDivElement> & {
    PartnerLogo?: React.JSXElementConstructor<React.SVGProps<SVGSVGElement>>;
  }
) {
  const { className, children, PartnerLogo, ...rest } = props;
  return (
    <div
      className={cn(
        "bg-surface-card text-btn-default border-border-light relative box-border flex w-full flex-row items-center justify-center border-b-[1px] border-solid p-6 text-left text-[13px] md:bg-[unset]",
        className
      )}
      {...rest}
    >
      <div className="flex w-[1392px] max-w-[1440px] flex-row items-center justify-between">
        {!PartnerLogo ? (
          <PopLogoWithTextSVG className="h-8 md:h-[42px]" />
        ) : (
          <div className="flex flex-row items-center justify-start gap-1 sm:gap-2">
            <PopLogoSVG className="h-8 sm:h-9" />
            <ArrowLeftRightIcon className="text-icon h-5 sm:h-6" />
            <PartnerLogo className="h-6 sm:h-7" />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
