import { createFileRoute, redirect } from "@tanstack/react-router";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import * as z from "zod";
import { postMagicLinkSignIn, getApplications } from "@/api";
import { getRouteByApplicationStage } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Header } from "@prime/pop-components/src/layout";
import { Button } from "@prime/ui/src/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@prime/ui/src/form";
import { Input } from "@prime/ui/src/input";
import { AxiosError } from "axios";

import XeroLoginLogoSVG from "./-components/xero-login-logo.svg";

const ERROR_OPTIONS = [
  "login_failed",
  "missing_offer",
  "authentication_required",
] as const;

const homepageSearchSchema = z.object({
  error: z.enum(ERROR_OPTIONS).optional(),
});

export const Route = createFileRoute("/")({
  validateSearch: homepageSearchSchema,
  beforeLoad: async ({ context, search }) => {
    const { auth, apiClient } = context;

    // ?error=authentication_required
    if (search.error === "authentication_required") {
      context.toast({
        title: "Authenticated required",
        description: "Please log in to access this route.",
        variant: "destructive",
      });
    }

    // ?error=login_failed
    if (search.error === "login_failed") {
      context.toast({
        title: "Login failed",
        description: "We were unable to log you in. Please try again.",
        variant: "destructive",
      });
    }

    // ?error=missing_offer
    if (search.error === "missing_offer") {
      context.toast({
        title: "Offer does not exist",
        description:
          "We were unable to find the offer you were looking for. Please try again.",
        variant: "destructive",
      });
    }

    const { error, isAuthenticated, user } = auth;

    if (!error && isAuthenticated && user) {
      if (!user.email)
        throw new Error(
          "Attempting to login authenticated user, but no email registered to user"
        );

      try {
        const { applications = [] } = await getApplications({
          apiClient,
        });

        if (!applications?.length) {
          if (user?.sub?.includes("xero")) {
            throw redirect({
              to: "/xero/welcome",
            });
          }
          return;
        }

        const { to: redirectTo, params: redirectParams } =
          getRouteByApplicationStage(applications?.[0]);

        throw redirect({
          to: redirectTo,
          params: redirectParams,
          replace: true,
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
        } else {
          // This is a purposeful redirect "throw" and we don't want to catch it
          throw error;
        }
      }
    }
  },
  component: Page,
});

const formSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email address" }),
});

function Page() {
  const { unauthenticatedApiClient, toast } = Route.useRouteContext();
  const [processing, setProcessing] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const redirectUri = useMemo(() => `${window.location.origin}/authorize`, []);

  const signInWithXero = useCallback(() => {
    const applyApiUrl = import.meta.env.VITE_APPLY_API_URL;
    window.location.href = `${applyApiUrl}/auth/connection?connection=xero&redirect_uri=${redirectUri}`;
  }, [redirectUri]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    const email = values.email;

    setProcessing(true);

    postMagicLinkSignIn({
      apiClient: unauthenticatedApiClient,
      payload: { email, redirect_uri: redirectUri },
    })
      .then(() => {
        setEmailSent(email);
      })
      .catch(() => {
        toast({
          title: "Login failed",
          description: "We were unable to log you in. Please try again.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  const buttonDisabled = processing;

  return (
    <div className="flex min-h-screen w-screen flex-col">
      <Header />
      <div className="flex w-full flex-1 items-start justify-center sm:mt-[120px]">
        <div className="flex w-full max-w-[480px] flex-col gap-6 p-6">
          <div className="gap-1">
            <p className="text-txt-primary text-2xl font-normal">
              {!emailSent ? "Sign In" : "Check Your Email"}
            </p>
            <p className="text-txt-tertiary text-sm">
              {!emailSent ? (
                <>
                  Please enter the email address you used to create your
                  account.
                </>
              ) : (
                <>
                  We sent an email to{" "}
                  <span className="font-medium">{emailSent}</span> containing a
                  verification link.
                </>
              )}
            </p>
          </div>

          {!emailSent ? (
            <>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="flex w-full flex-col gap-12"
                >
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            type="email"
                            className="text-sm"
                            placeholder="Email Address"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button
                    type="submit"
                    className="h-12 w-full rounded-xl px-5 py-3 text-sm font-medium"
                    disabled={buttonDisabled}
                  >
                    Sign in with Email
                  </Button>
                </form>
              </Form>
              <div className="bg-border-dark relative h-px w-full" />
              <h3 className="text-txt-tertiary text-sm">
                Used a Xero account to get started with Pop?
              </h3>
              <Button
                onClick={signInWithXero}
                className="h-12 w-full gap-3 rounded-xl bg-[#1AB4D7] px-5 py-3 text-sm font-medium hover:bg-[#1590AC]"
              >
                <XeroLoginLogoSVG className="h-8 w-8" />
                Sign in with Xero
              </Button>
            </>
          ) : (
            <div>
              <Button
                onClick={() => setEmailSent(null)}
                variant={"outline"}
                className="h-12 rounded-md border-[2px] px-3 py-2 text-sm font-medium"
              >
                Start Over
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
